
@media (min-width:0px) and (max-width:1000px) {

    .container {

        .template {
            .template_content {
                margin: 4em 0 !important;
                padding: 1em !important;
                word-break: break-word;

                h3 {
                    text-align: center;
                    color: #08c;
                    margin-bottom: 20px;
                    font-size: 24px;
                }
            }
        }
    }
}
