
@media (min-width:1000px) {
}

@media (min-width:1400px) {
}


@media (min-width:0px) and (max-width:600px) {
}

@media (min-width:0px) and (max-width:350px) {

    .container {

        .template {
            .template_content {
                margin: 3em 0;
                padding-top: 10px;
                word-break: break-all;
                h3 {
                    text-align: center;
                    color: #08c;
                    margin-bottom: 20px;
                    font-size: 24px;
                }
            }
        }
    }
}
