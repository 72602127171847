

/*AHMET*/

.joinOurTeam {
    box-shadow: 0px 5px 5px 0px black;
}


@media (min-width:1000px) {

    .wrapper {
        margin-top: 32px !important;
    }
    #part1 {
        margin: 20px 0 80px 0;

        strong {
            h1 {
                color: #0088cc !important;
                font-size: 35px;
                text-align: center;
                font-weight: 700;
                text-transform:uppercase;
            }
        }


        .container {
            .row {
                &:first-child {
                    .col {
                        text-align: left;

                        span {
                            &:first-child {
                                color: black;
                                font-size: 30px;
                            }

                            &:last-child {
                                background-color: #0088cc;
                                color: #fff;
                                font-size: 22px;
                                padding: 7px 20px;
                                margin: 3px;
                            }
                        }
                    }
                }

                &:last-child {

                    div {
                        &:first-child {
                            p {
                                text-align: left;
                                font-weight: 400;
                                letter-spacing: 0px;
                            }
                        }

                        &:last-child {
                            text-align: left;

                            a {
                                background-color: #0088cc;
                                border-color: #0088cc;
                                padding: 10px 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    #part2 {
        margin: 60px 0 60px 0;

        h1 {
            font-size: 25px;
            font-weight: 700;
            margin: 10px 0;
        }

        p {
            text-align: justify;
            line-height: 1.3;
        }
    }



    #part3 {
        padding: 0 0 140px 0;

        h1 {
            font-size: 36px;
            color: #555;
            font-weight: bold;
        }

        h3 {
            font-size: 24px;
            color: #333;
            font-weight: bold;
        }

        h5 {
            padding: 10px 0;
            font-weight: 500;
        }



        #team i {
            font-size: 26px;
            color: #555;
        }

        #team p {
            font-weight: 500;
        }

        #team .card {
            border-radius: 0;
            box-shadow: 5px 5px 15px #0088cc;
            transition: all 0.3s ease-in;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
        }

        #team .card:hover {
            background: #0088cc;
            color: #fff;
            border-radius: 5px;
            border: none;
            box-shadow: 5px 5px 10px #9e9e9e;
        }

        #team .card:hover h3,
        #team .card:hover i {
            color: #fff;
        }
    }






}





@media (min-width:1400px) {
}











@media (min-width:992px) and (max-width:1399px) {
}








@media (min-width:0px) and (max-width:1000px) {


    .wrapper {
        margin-top: 22px !important;
    }


    #part1 {
        margin: 20px 0;

        strong {
            h1 {
                color: #0088cc !important;
                font-size: 25px;
                text-align: center;
                font-weight: 700;
            }
        }


        .container {
            .row {
                &:first-child {
                    .col {
                        text-align: left;

                        span {
                            &:first-child {
                                color: black;
                                font-size: 20px;
                            }

                            &:last-child {
                                background-color: #0088cc;
                                color: #fff;
                                font-size: 16px;
                                padding: 7px 10px;
                                margin: 3px;
                            }
                        }
                    }
                }

                &:last-child {

                    div {
                        &:first-child {
                            p {
                                text-align: left;
                                font-weight: 400;
                                letter-spacing: 0px;
                                margin: 0 0 20px 0;
                                text-align: justify;
                            }
                        }

                        &:last-child {
                            text-align: center;


                            a {
                                background-color: #0088cc;
                                border-color: #0088cc;
                                padding: 10px 20px;
                                margin-top: 20px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    #part2 {
        margin: 40px 0;

        h1 {
            font-size: 25px;
            font-weight: 700;
            margin: 30px 0 10px;
            text-align: center;
            color: #0088cc;
        }

        p {
            text-align: justify;
            line-height: 1.3;
        }
    }



    #part3 {
        padding: 0 0 40px 0;

        h1 {
            font-size: 36px;
            color: #555;
            font-weight: bold;
        }

        h3 {
            font-size: 24px;
            color: #333;
            font-weight: bold;
        }

        h5 {
            padding: 10px 0;
            font-weight: 500;
        }

        .col-lg-3.col-md-6 {
            margin: 20px 0;
        }

        #team i {
            font-size: 26px;
            color: #555;
        }

        #team p {
            font-weight: 500;
        }

        #team .card {
            border-radius: 0;
            box-shadow: 5px 5px 15px #0088cc;
            transition: all 0.3s ease-in;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
        }

        #team .card:hover {
            background: #0088cc;
            color: #fff;
            border-radius: 5px;
            border: none;
            box-shadow: 5px 5px 10px #9e9e9e;
        }

        #team .card:hover h3,
        #team .card:hover i {
            color: #fff;
        }
    }


















}








