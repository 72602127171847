// Here you can add other styles

.usernameText {
    color: #3c4b64 !important;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 5px;
}

.sessionLimitFooter {
    position: fixed !important;
    left:0;
    right: 0;
    bottom: 0;
    z-index: 99;
}

.mrright-50 {
    margin-left: 50%;
}
