.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.header-container {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .header_content {
        width: 70%;
        display: flex;
        justify-content: space-between !important;

        .header_right {
            .nav {
                display: flex;
                align-items: center;

                .login-button-hover {
                    background: #FF6500 !important;
                    padding: 5px 20px;
                    border-radius:4px;
                    border: 1px solid #FF6500 !important;
                }

                .login-button-hover:hover {
                    background: white !important;
                    color: #FF6500 !important;

                }
            }

            .loggedin {
                border: none !important;

                li {
                    border: none !important;
                }

                a {
                    background: #FF6500 !important;
                    color: white !important;
                }
            }

            li {
                width: fit-content;

                a {
                    color: black !important;
                    font-size: 24px;
                }
            }
        }
    }
}

.login-button-hover:hover {
    background: white !important;
    color: #0088cc !important;
}
.register-button-hover:hover {
    background: white !important;
    color: #FF6500 !important;
}
/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}
.mobil-menu-h {
  @media only screen and (min-width: 990px) { 
    display: none;
  }
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.navVertical {
  li {
    margin-top: 5px;
    border: 1px solid darkgray;
    padding: 5px;
    border-radius: 4px;
  }
}