
@media (min-width:1000px) {

    .embed444card {
        margin: auto;
        position: relative;
        top: 80px;
        background-color: white;
        padding-top: 50px;
        max-width: 1390px;
    }
}

@media (min-width:1400px) {
}


@media (min-width:0px) and (max-width:1000px) {
    .embed444card {
        margin: auto;
        position: relative;
        top: 80px;
        background-color: white;
        padding-top: 50px;
        max-width: 82%;
        margin-bottom: 50px;
    }
    .container {

        

        .template {
            .template_content {
                margin: 1em 0 !important;
                padding: 1em;


                h3 {
                    text-align: center;
                    color: #08c;
                    margin-bottom: 20px;
                }

                u {
                }
            }
        }
    }
}

@media (min-width:0px) and (max-width:350px) {

    .container {
        padding-right: 5px !important;
        padding-left: 5px !important;

        .template {
            .template_content {
                margin: 1em 0 !important;
                padding: 1em;

                h3 {
                    text-align: center;
                    color: #08c;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
