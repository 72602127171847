.footer-container {
  background: white;
  li {
    a {
      color: black !important;
    }
  }
  .footer_right {
    color: black !important;
  }
}
@media (max-width: 768px) {
  .label {
    margin-top: -20px !important;
  }
  .footer-img {
   margin-bottom: -10px !important;
  }
}
.fab {
  font-size: 24px; 
}
.link {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
}
.footer-img {
  display : flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -10px !important;
}
